import '@amedia/brick-button';
import { html, render } from 'lit-html';
import { unsafeHTML } from 'lit-html/directives/unsafe-html.js';
import { classMap } from 'lit-html/directives/class-map.js';
import queueMicrotask from 'queue-microtask';

import {
  wrapperStyle,
  chapterWrapperStyle,
  chapterTitleStyle,
  teaserStyle,
} from './assets/styles.js';
import {
  createStore,
  applyMiddleware,
  rootReducer,
  actions,
  middlewares,
  createReactiveProperties,
  types,
  DEFAULT_PLAYER_ID,
} from './model/index.js';
import deepEqual from './utils/deepEqual.js';
import { getCustomCSSProperties } from './model/selectors.js';

const FLOWPLAYER_HTML = '<div class="flowplayer lp_flowplayer"></div>';

export class BrickPlayer extends HTMLElement {
  constructor() {
    super();
    this.store = null;
    this.player = null;
    this.render = () => render(this.template, this, { host: this });
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    this.destroy = () => {};
  }

  static properties = {
    aspectRatio: { type: types.AspectRatio, attribute: 'aspectratio' },
    hasTeaserTitle: { type: types.Boolean, attribute: 'teasertitle' },
    isAdsDisabled: { type: types.Boolean, attribute: 'adsdisabled' },
    isAudio: { type: types.Boolean, attribute: 'audio' },
    isAutoPlay: { type: types.Autoplay, attribute: 'autoplay' },
    isMuted: { type: types.Boolean, attribute: 'muted' },
    isScrollEnabled: { type: types.Boolean, attribute: 'scrollenabled' },
    playerId: {
      type: types.PlayerId,
      attribute: 'playerid',
      defaultValue: DEFAULT_PLAYER_ID,
    },
    startTime: { type: types.Float, attribute: 'start', defaultValue: 0 },
    startQuality: {
      type: types.StartQuality,
      attribute: 'startquality',
    },
    timeLeft: {
      type: types.Float,
      attribute: 'timeleft',
      defaultValue: Infinity,
    },
    logo: { type: types.Image, attribute: 'logo', defaultValue: null },
    loop: { type: types.Boolean, attribute: 'loop' },
    uiOverrides: { type: types.UiFlags, attribute: 'ui' },
    mediaId: { type: types.String, attribute: 'mediaid' },
    nextVideo: {
      type: types.NextVideo,
      attribute: 'nextvideo',
      defaultValue: null,
    },
    playOutsideViewport: {
      type: types.Boolean,
      attribute: 'playoutsideviewport',
    },
    videoTitle: { type: types.String, attribute: 'title' },
  };

  static get observedAttributes() {
    return Object.values(BrickPlayer.properties).map(
      ({ attribute }) => attribute
    );
  }

  _attributeChangedCallback = Function.prototype;

  // attributeChangedCallback must be defined on the element prototype
  // to work as expected.
  attributeChangedCallback(...args) {
    this._attributeChangedCallback(...args);
  }

  disconnectedCallback() {
    this.destroy();
  }

  connectedCallback() {
    this.style.display = 'block';
    const { properties } = BrickPlayer;
    this.store = createStore(rootReducer, applyMiddleware(...middlewares));
    const { id, version } = this.store.getState();
    this.setAttribute('data-id', id);
    this.setAttribute('data-testid', 'brick-player');
    const { attributeChangedCallback, detachStore } = createReactiveProperties(
      this,
      properties,
      this.store
    );
    this._attributeChangedCallback = attributeChangedCallback;
    this.store.dispatch(actions.loadPlayer(this));
    const unsubscribeStore = this.store.subscribe((prevState, state) => {
      queueMicrotask(() => this.render());
      Object.entries(properties).forEach(([property, { attribute, type }]) => {
        if (!deepEqual(prevState[property], state[property])) {
          const next = type.toString(state[property]);
          const detail = {
            id,
            [attribute]: {
              prev: type.toString(prevState[property]),
              next,
            },
          };
          this.setAttribute(attribute, next);
          this.dispatchEvent(
            new CustomEvent(`brick-player:${attribute}`, {
              detail,
              bubbles: true,
            })
          );
        }
      });
    });
    this.destroy = () => {
      unsubscribeStore();
      detachStore();
      this.store.getState().player?.destroy();
      delete this.store;
      delete this._attributeChangedCallback;
      this.dispatchEvent(
        new CustomEvent('brick-player:disconnected', {
          detail: { id, engine: 'flowplayer', version },
          bubbles: true,
        })
      );
    };
    this.dispatchEvent(
      new CustomEvent('brick-player:connected', {
        detail: { id, engine: 'flowplayer', version },
        bubbles: true,
      })
    );
    this.render();
  }

  replay() {
    this.store.dispatch(actions.replay());
  }

  skip() {
    this.store.dispatch(actions.skip());
  }

  play() {
    this.store.dispatch(actions.play());
  }

  pause() {
    this.store.dispatch(actions.pause());
  }

  onChapterClick({
    currentTarget: {
      dataset: { time },
    },
  }) {
    this.store.dispatch(actions.seekedTo(parseInt(time, 10)));
  }

  onUnmute() {
    this.store.dispatch(actions.unmute());
  }

  get template() {
    const {
      hasTeaserTitle,
      isAudio,
      aspectRatio,
      chapters = [],
      playerState = {},
      videoTitle,
      player,
      shouldShowUnmute,
    } = this.store.getState();

    const inlineCssProperties = getCustomCSSProperties({
      aspectRatio,
      videoTitle: videoTitle || (player?.opts?.metadata?.title ?? 'No title'),
      hasTeaserTitle,
    });
    return html`
      <div
        class="${classMap({
          [wrapperStyle]: true,
          [teaserStyle]: hasTeaserTitle,
          'brick-player-audio': isAudio,
          'brick-player-video': !isAudio,
        })}"
        style="${inlineCssProperties || ''}"
      >
        ${shouldShowUnmute
          ? html`<brick-button-v7
                  @click="${this.onUnmute}"
                  data-adp-clikclabel="video-toggle-volume"
                  data-adp-clickvalue="unmute"
                  class="unmute-autoplay"
                  data-version="outlined"
                  data-iconid="volume-off"
                  data-label="Slå på lyd"></brick-icon-v2>
              </brick-button-v7>`
          : ''}
        ${!playerState.isLoaded
          ? html`<div class="loader">
              <svg
                version="1.1"
                id="L9"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 100 100"
                enable-background="new 0 0 0 0"
                xml:space="preserve"
              >
                <path
                  fill="#000"
                  d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                >
                  <animateTransform
                    attributeName="transform"
                    attributeType="XML"
                    type="rotate"
                    dur="1s"
                    from="0 50 50"
                    to="360 50 50"
                    repeatCount="indefinite"
                  />
                </path>
              </svg>
            </div>`
          : ''}
        ${unsafeHTML(FLOWPLAYER_HTML)}
      </div>
      ${chapters.length > 0
        ? html`
            <ol class="${chapterWrapperStyle}">
              ${chapters.map(
                ({ title, time }) => html` <li class="${chapterTitleStyle}">
                  <button @click="${this.onChapterClick}" data-time="${time}">
                    ${title}
                  </button>
                </li>`
              )}
            </ol>
          `
        : ''}
    `;
  }
}

if (!customElements.get('brick-player')) {
  customElements.define('brick-player', BrickPlayer);
}
