import bootstrapFlowplayer from '../../assets/flowplayer.js';
import {
  prebidInstreamHandler,
  getImaConfig,
} from '../../utils/prebidInstreamHandler.js';

import { selectPlayerConfig } from '../selectors.js';
import {
  LOAD_PLAYER,
  PLAYER_LOADED,
  ADPLOGGER_SDK_LOADED,
  actions,
} from '../actions.js';

export const loadPlayerMiddleware = (store) => (next) => (action) => {
  if (action.type !== LOAD_PLAYER) {
    return next(action);
  }
  const state = store.getState();
  const element = action.payload;
  const {
    version,
    isAudio,
    isAdsDisabled,
    isDebugLogging,
    features,
    playerId,
    mediaId,
  } = state;
  Promise.all([
    bootstrapFlowplayer({
      element,
      version,
      isAudio,
      isAdsDisabled,
      isDebugLogging,
      playerId,
      mediaId,
    }),
    import('./adpLogger.js').catch(console.warn),
    features.useBazaarLwhb || isAdsDisabled || isAudio
      ? Promise.resolve()
      : prebidInstreamHandler().catch((e) =>
          e.message === 'Bazaar not defined'
            ? console.log(e.message)
            : console.warn(e)
        ),
  ]).then(([{ flowplayer, ovpConfig }, adpLogger]) => {
    store.dispatch(actions.ovpConfigLoaded(ovpConfig));
    const player = flowplayer(element.querySelector('.flowplayer'), {
      ...selectPlayerConfig(state),
      ...(features.useBazaarLwhb &&
        !isAdsDisabled &&
        !isAudio &&
        getImaConfig(store)),
    });
    store.dispatch({ type: PLAYER_LOADED, payload: { player } });
    if (!isAudio && adpLogger) {
      store.dispatch({ type: ADPLOGGER_SDK_LOADED, payload: { adpLogger } });
    }
  });
  return next(action);
};
